import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import queryString from "query-string";
import Img from "gatsby-image";
import { _localizedSlug } from "../core/utils";
import { Layout } from "../components/Layout";

export const query = graphql`
  query SearchPagesFr {
    allPrismicArticle(filter: { lang: { eq: "fr-fr" } }) {
      nodes {
        type
        uid
        lang
        data {
          title {
            text
          }
          image {
            ...sharp
          }
        }
      }
    }
  }
`;

class SearchPage extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);

    const { data, location } = this.props;
    const pagePaths = data.allPrismicArticle.nodes.map((node) => node.uid);
    const urlState = queryString.parse(location.search);
    console.log(pagePaths);
    const initialSearchTerms = urlState.s ? urlState.s : ``;
    const filteredPaths = this.getFilteredPagePaths(
      pagePaths,
      initialSearchTerms
    );
    console.log(initialSearchTerms);

    const result = data.allPrismicArticle.nodes.filter((el) => {
      // console.log(el.data.title.text);
      // const uidSearch = filteredPaths.includes(el.uid);
      const exist =
        filteredPaths.filter((path) =>
          path.includes(el.data.title.text.toLowerCase())
        ).length > 0;
      // const exist = filteredPaths.includes(el.data.title.text.toLowerCase());
      console.log(exist);
      return exist;
    });
    console.log(result.length);
    this.state = {
      initPagePaths: pagePaths,
      searchTerms: initialSearchTerms,
      pagePaths: filteredPaths,
      result: result,
    };
  }

  componentDidMount() {
    // console.log(this.state);
  }

  getFilteredPagePaths(allPagePaths, searchTerms) {
    const searchTerm = new RegExp(`${searchTerms}`, "i");
    return allPagePaths.filter((pagePath) => searchTerm.test(pagePath));
  }

  render() {
    const lang = {
      lang: "fr-fr",
    };
    return (
      <div className="search ">
        <div className="row">
          <div className="col-md-10 col-xs-12">
            <div className="row bottom-xs">
              {this.state.result.map((node, i) => (
                <div className="col-md-3 col-xs-12 mb-md" key={i}>
                  <Link to={_localizedSlug(node)}>
                    <Img {...node.data.image} />
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-2 hidden-xs"></div>
        </div>
      </div>
    );
  }
}

export default SearchPage;
